/* HAMBURGER BUTTON */
.thehamburger {
	cursor: pointer;
	float: left;
	margin: 12px 6px 0px 10px; /* Top - Right - Bottom - Left */
	color: var(--colour-hamburger-menu);
}

.bar1,
.bar2,
.bar3 {
	width: 20px;
	height: 2px;
	margin: 5px 0;
	transition: 0.4s;
}

.change .bar1 {
	-webkit-transform: rotate(-45deg) translate(-5px, 4px);
	transform: rotate(-45deg) translate(-5px, 4px);
}

.change .bar2 {
	opacity: 0;
}

.change .bar3 {
	-webkit-transform: rotate(45deg) translate(-5.5px, -5.5px);
	transform: rotate(45deg) translate(-5.5px, -5.5px);
}

/* The container of the bars - needed to position the dropdown content */
.dropdown {
	display: inline-block;
	border: none;
	outline: none;
	background-color: var(--colour-head-foot);
}

/* DROPDOWN MENU */
.dropbutton {
	background-color: var(--textcolour-title-footer);
	padding: 0;
	cursor: pointer;
}

/* Dropdown Content (Hidden by Default) */
.dropdowncontent {
	display: none;
	position: absolute;
	background-color: var(--drop-content-background);
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
}

/* Links inside the dropdown */
.dropdowncontent a {
	color: var(--drop-content-text);
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdowncontent a:hover {
	background-color: var(--drop-content-background-hover);
}

/* Show the dropdown menu @ hamburger */
.show {
	display: block;
}
