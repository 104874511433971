body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

.footer {
  background-color: var(--colour-head-foot);
  color: var(--textcolour-title-footer);
  text-align: center;
  width: 100%;
  height: var(--footer-height, 50px); /* create a variable and set it to 70 */
  margin-top: auto; /* push the footer to the bottom */
  display: flex;
  align-items: center; /* vertical alignment */
  justify-content: center; /* horizontal alignment */
}

p.footerText {
  font-family: var(--font-footer);
  font-size: 15px;
}
