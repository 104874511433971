/* Homepage styling */
.pagewrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height) - var(--footer-height)); /* Adjust for header and footer */
  background-color: #0d1117;
  width: 100%;
  margin: auto;
  padding-top: 0.1px;
}

.textwrapper {
	height: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 5%;
    margin-right: 5%;
    text-align: left;
}

.subtitle {
    padding: 1rem;
    font-weight: 300;
    font-size: 0.9rem;
    letter-spacing: 1px;
    font-family: Source Sans Pro,sans-serif;
    color:white
}