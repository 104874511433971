:root {
  --sidebar-width: 20vw;
}

.dashexplorer {
  background: var(--sidebar-background);
  width: var(--sidebar-width);
  height: 100%;
  max-width: 200px;
  font-family: Source Sans Pro, sans-serif;
}

.dashexplorer_title {
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  color: var(--textcolour-explorer);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.dashexplorer_tabs {
  padding: 0;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.dashexplorer_tab {
  box-sizing: border-box;
  padding: 0.4rem 0.4rem;
  overflow: hidden;
  max-width: 100%;
  display: flex; /* make it a flex container to align img and p horizontally */
  align-items: center; /* align vertically center within box */
}

.dashexplorer_tab:hover {
  background-color: #808080;
}

.selected_tab {
  background-color: var(--dashboard-background);
}

.dashexplorer_tab img {
  max-width: 100%;
  width: 20px;
  height: auto;
  margin-right: 5px; /* spacing between img and p */
}

.dashexplorer_tab p {
  margin: 0; /* remove default margin to prevent extra space */
  color: #dcdcdc;
}