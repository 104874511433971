/* Table styles |.th = table header and .td = data cells*/

.table {
  border-collapse: collapse;
}

.scrollable_div {
  height: 90%; /* dynamic, calculated based on parent */
  overflow: auto; /* add a scrollbar if the content overflows */
  border: 1px solid #ddd;
  background-color: var(--table-background);
}

.th, .td {
  border: 1px solid #ddd;
  text-align: left;
  word-wrap: break-word;
}

/* Additional styling for specific columns */

/* All columns have the same width except Vendor and cc number */
.th, .td {
  width: 90px;
}

/* Additional styling for specific columns */
.th:nth-child(2), .td:nth-child(2) {
  width: 400px;
}

.th:nth-child(5), .td:nth-child(5) {
  width: 130px;
}