/* Alignment of <div> objects that are links in the header */
.alignheaderdiv {
	float: right;
}

/* dropdown object (container) */
.dropdown {
	display: inline-block;
	border: none;
	outline: none;
}

/* dropdown content is shown on hover over dropdown object */
.dropdown:hover .dropdowncontent {
	display: block;
}

/* dropdown content (Hidden by Default) */
.dropdowncontent {
	display: none;
	position: absolute;
	background-color: var(--drop-content-background);
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
}

/* Links inside the dropdown */
.dropdowncontent a {
	color: var(--drop-content-text);
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdowncontent a:hover {
	background-color: var(--drop-content-background-hover);
}

/* dropdown object label style */
.dropnavlink {
	color: var(--textcolour-links);
	background-color: var(--colour-head-foot);
	font-family: var(--font-headerlinks);
	text-align: center;
	padding: 12px;
	font-size: 18px;
	line-height: 25px;
	border: none;
}

/* Change label colour on hover */
.dropnavlink:hover {
	color: var(--textcolour-link-hover);
}

/* Right align dropdown content at pages to prevent squashing */
.aligndroplink {
	/* minimum space at right edge of screen */
	margin-right: 24px;
	/* start at text */
	margin-left: 12px;
}
