.HeaderLink {
	color: var(--textcolour-links);
	font-family: var(--font-headerlinks);
	float: right;
	text-align: center;
	padding: 12px;
	font-size: 18px;
	line-height: 25px;
}

.HeaderLink:hover {
	color: var(--textcolour-link-hover);
}

/* Make the label for the current page: bold */
.HeaderLink .currentpage {
	font-weight: 1000;
}
