/* dark and light theme toggle switch */
.switchwrapper {
	display: inline-flex;
	align-items: center;
	background-color: var(--switch-container-background);
	padding: 3px;
	width: 100%;
	box-sizing: border-box; /* when applied to both parent and child, prevents child from overflowing parent under "width:100%" due to border/other inclusions */
}

.switchwrapper em {
	margin: auto;
	margin-left: 5px;
	font-size: 1rem;
	color: var(--textcolour-title-footer);
}

.switch {
	display: inline-flex;
	height: 17px;
	position: relative;
	width: 30px;
	margin: auto;
	margin-right: 0;
}

.switch input {
	display: none;
}

.slider {
	background-color: var(--theme-switch-background);
	bottom: 0;
	cursor: pointer;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: 0.2s;
}

.slider:before {
	background-color: var(--theme-switch);
	bottom: 4px;
	content: '';
	height: 10px;
	left: 4px;
	position: absolute;
	transition: 0.2s;
	width: 10px;
}

input:checked + .slider {
	background-color: var(--theme-switch-background);
}

input:checked + .slider:before {
	transform: translateX(12px);
}

.slider.round {
	border-radius: 5px;
}

.slider.round:before {
	border-radius: 10%;
}
