/* Site Map styling */
:root {
  --pagewrapper-height: calc(100vh - var(--header-height) - var(--footer-height));
}

.pagewrapper {
  background-color: #404040;
  display: flex;
  flex-direction: row;
  min-height: var(--pagewrapper-height);
  width: 100%;
  margin: auto;
  padding-top: 0.1px;
}

.textwrapper {
  width: fit-content;
  height: fit-content;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 5%;
  margin-right: 5%;
  text-align: left;
}
