/* Portfolio styling */
.pagewrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height) - var(--footer-height)); /* Adjust for header and footer */
  width: 100%;
  margin: auto;
  padding-top: 0.1px;
}

.textwrapper {
	width: fit-content;
	height: fit-content;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 5%;
    margin-right: 5%;
    text-align: left;
}
