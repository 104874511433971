/* Global header css */

.header {
  background-color: var(--colour-head-foot);
  overflow: hidden;
  padding: 0px;

  /* default value for header height - to be overwritten later */
  --header-height: var(--header-height, 50px); /* default to 50px if not set */
}
