.card {
	/* properties of each card */
	background-color: rgb(98, 117, 134);
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); /* shadow creats card effect */
	transition: 0.3s;
	border-radius: 5px; /* 5px rounded corners */
	width: 300px;
	height: 400px;
	/* margin:50px; */
}

.card img {
	/* the card image */
	border-radius: 5px 5px 0 0; /* rounded corners at top */
	width: 100%;
	height: 75%;
	object-fit: cover; /* zoom setting */
}

.card:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); /* darker shadow on hover */
}

.cardlabel {
	/* the card text/label */
	color: black;
	font-family: var(--font-headerlinks);
	font-size: 20px;
	padding: 0px 16px;
	margin: 12px;
	width: auto;
	height: auto;
}
