/* TEAL BACKING FOR CARDS */
.cardspage {
  /* holds all the cards */
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height) - var(--footer-height)); /* Adjust for header and footer */
  background-color: teal;
  width: 100%;
  margin: auto;
  padding-top: 0.1px;
}

/* GRID SYSTEM FOR CARDS */
.cards {
	/* rules for arranging cards on the page */
	display: grid;
	grid-template-columns: repeat(auto-fill, 300px);
	row-gap: 50px;
	column-gap: 25px;
	justify-content: space-evenly;
	margin-top: 50px;
	padding-bottom: 50px;
}
