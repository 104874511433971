/* Styles for the verb conjugation table */

.myTable {
  border-collapse: collapse;
  width: 100%;
}

.columnHeader {
  padding: 8px;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  color: white;
  font-family: "Consolas", "Courier New", monospace;
}

.dataCell {
  padding: 8px;
  text-align: left;
  font-size: 20px;
  color: white;
  font-family: "Consolas", "Courier New", monospace;
}

/* Style for the first column (pronouns column) */
.indexColumn {
  font-weight: bold;
  min-width: 120px;
  font-size: 20px;
  color: white;
  font-family: "Consolas", "Courier New", monospace;
}