/* Dashboard styling */
:root {
  --pagewrapper-height: calc(100vh - var(--header-height) - var(--footer-height));
  --dashwrapper-height: calc(0.75 * var(--pagewrapper-height));
}

.pagewrapper {
  display: flex;
  flex-direction: row;
  min-height: var(--pagewrapper-height);
  width: 100%;
  margin: auto;
  padding-top: 0.1px;
}

.textwrapper {
  width: fit-content;
  height: fit-content;
  margin-top: 50px;
  margin-bottom: 50px; /* same as footer size */
  margin-left: 5%; /* if you change left/right, adjust dashbody as well: 100vw - left - right */
  margin-right: 5%;
  text-align: left;
}

.dashwrapper {
  display: flex;
  flex-direction: row;
  height: var(--dashwrapper-height);
}

.dashbody {
  background: var(--dashboard-background);
  width: calc(90vw - var(--sidebar-width));
  height: 100%;
  max-height: 80vh;
  border-right: 1px solid black;
  font-family: Source Sans Pro, sans-serif;
  padding: 2rem;
}