.explorer {
  background: #1f2428;
  width: 20vw;
  max-width: 200px;
  border-right: 1px solid black;
  font-family: Source Sans Pro, sans-serif;
}

.explorer_title {
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--textcolour-explorer);
  padding: 0.5rem 0.5rem 1.5rem 0.5rem;
}

.explorer_heading {
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--textcolour-explorer);
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Expansion arrow next to the heading */
.explorer_chevron {
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    height: 8px;
    width: 8px;
    transform: rotate(-45deg);
    transition: transform 0.2s ease;
    margin-right:0.4rem;
}

.rotated{
  transform: rotate(45deg);
}

/* other shit */
.explorer_files {
  padding: 0.5rem;
  cursor: pointer;
  display: block;
}

.explorer_file {
  box-sizing: border-box;
  padding: 0.1rem 0.1rem 0.4rem;
  overflow: hidden;
  max-width: 100%;
  display: flex; /* make it a flex container to align img and p horizontally */
  align-items: center; /* align vertically center within box */
}

.explorer_file img {
  max-width: 100%;
  width: 20px;
  height: auto;
  margin-right: 5px; /* spacing between img and p */
}

.explorer_file p {
  margin: 0; /* remove default margin to prevent extra space */
  color: white;
}